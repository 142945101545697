import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '@snsw-gel/react';
import routes from 'config/routes';
import handleQuitTransaction from 'utils/routes/quitTransaction';


const OnlineNotificationsModal: React.FC<OnlineNotificationsModalProps> = ({
  onCloseModal,
}) => {
  const history = useHistory();
  const handleContinueButton = () => {
    history.push(routes.checkEligibility.path);
  };

  return (
    <Modal
      id='dkt-online-notifications'
      title='DKT online notifications'
      onClose={onCloseModal}
      buttons={[
        { text: 'I accept', onClick: handleContinueButton },
        { text: 'Quit', onClick: handleQuitTransaction.redirectToDrupal },
      ]}
    >
      <p>
        As part of enrolling in DKT online, you will receive notifications about
        your progress and important information related to next steps.
      </p>
      <p>
        These will be sent to your email provided as part of your MyServiceNSW
        Account login. You’ll receive notifications for:
      </p>
      <ol>
        <li>When you successfully enrol in DKT online, and</li>
        <li>When you successfully pass DKT online.</li>
      </ol>
      <p>
        Please save the emails for future reference.
      </p>
    </Modal>
  );
};

export default OnlineNotificationsModal;

interface OnlineNotificationsModalProps {
  onCloseModal: () => void;
}
